/*!
 **********************************************************************************************
 * Company name: Jenex Technologies
 *
 * @file : dman-firmwares.service.ts
 * @file description: APIs - addfirmware, copyfirmware, upload, getfirmware, deletefirmware, editfirmware
 * @author : Asha Bheda
 *
 *********************************************************************************************/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DmanFirmwaresService {

  constructor(private http: HttpClient) { }

  AddFirmware(data): Observable<any> {
    console.log('service file add firmware req data', data);
    return this.http.post('/addfirmware', {data});
  }
  // CopyFirmware(data,oldId): Observable<any> {
  //   return this.http.post('/copyfirmware', {data,oldId:oldId});
  // }
  upload(id): Observable<any> {
    console.log('upload fn service call here ', id);
    return this.http.post('/upload', {id:id});
  }
  getfirmware(): Observable<any> {
    return this.http.get('/getfirmware');
  }

  deletefirmware(id):Observable<any>{
    // console.log('delete fw',  id);
    return this.http.delete(`/deletefirmware/${id}`);
  }
  EditFirmware(data,id): Observable<any> {
    // console.log('edit fw', data, id);
    
    return this.http.put(`/editfirmware/${id}`, data, { responseType: 'text' });
  }

}
