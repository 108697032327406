import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http: HttpClient) { }
  AddUser(userFormData) {
    return this.http.post<any>(`/add_user`, userFormData)
      .pipe(map(user => {
        console.log('users ', user);
        // login successful if there's a jwt token in the response
        if (user && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
        }

        return user;
      }));
  }

  user_list() {
    console.log("this is service");
    return this.http.get<any>('user_list', {})
  }

  update_user(updateUserData) {


    /* var newNum = "userid";
    var newVal = userId;
    updateUserData[newNum] = newVal;
    console.log("*****************", updateUserData) 

    console.log("USER.SERVICE.TS", userId);*/
    return this.http.put<any>(`/update_user`, updateUserData)
      .pipe(map(user => {
        return user;
      }));
  }
}
