import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class TransferBulkService {

  constructor(private http: HttpClient) { }

  //send imei to search devices
  searchDevice(imei,select):Observable<any>{
    return this.http.post('/searchDevice', {imei:imei,select:select});
  }
  //for get all last 30 days history of esn number
  // location(esn):Observable<any>{
  //   return this.http.post('/location',{esn:esn});
  // }
  //send imei to transfer devices
  deviceBulkTransfer(imei,to,check_history,from):Observable<any>{
    return this.http.post('/deviceBulkTransfer', {imei:imei,to:to,check_history:check_history,from:from});
  }
  //get recent status of esn number
  // getStatus(esn):Observable<any>{
  //   return this.http.post('/getStatus',{esn:esn});
  // }
  //reboot device
  reboot(esn): Observable<any> {
    return this.http.put(`/reboot/${esn}`, { responseType: 'text' });
  }
   //clear device
  //  clear(esn): Observable<any> {
  //   return this.http.put(`/clear/${esn}`, { responseType: 'text' });
  // }
    
}