/*!
 **********************************************************************************************
 * Company name: Jenex Technologies
 *
 * @file : account.service.ts
 * @file description: This file is for APIs accounts(get all accounts),editaccount,deleteaccount, logindashboard, viewaccount
 * @author : Unnati Balas
 *
 *********************************************************************************************/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private http: HttpClient) { }

  getaccounts(): Observable<any> {
    return this.http.get('/accounts');
  }

  countaccounts(): Observable<number> {
    return this.http.get<number>('/accounts/count');
  }

  addaccount(account): Observable<any> {
    return this.http.post('/account', account);
  }

  getaccount(account): Observable<any> {
    return this.http.get(`/accountid/${account}`);
  }

  editaccount(account, id): Observable<any> {
    return this.http.put(`/editaccount/${id}`, account, { responseType: 'text' });
  }

  deleteaccount(id): Observable<any> {
    return this.http.delete(`/deleteaccount/${id}`);
  }

  logindashboard(accid): Observable<any> {
    return this.http.get(`/logindashboard/${accid}`);
  }

  viewaccount(accid): Observable<any> {
    return this.http.get(`/viewaccount/${accid}`);
  }

  marineloginapi(data): Observable<any> {
    // return this.http.post("https://localhost:3001/login",{name:data.data.username,password:data.data.password});
    return this.http.post("https://login.themarineguardian.com/login", { name: data.data.username, password: data.data.password });
  }

  resendemail(resend): Observable<any> {
    return this.http.post('/resendemail', resend);
  }
}
