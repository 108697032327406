/*!
 **********************************************************************************************
 * Company name: Jenex Technologies
 *
 * @file : dman-firmwares.service.ts
 * @file description: APIs - addgrp, getgrps, deletegrp, editgrp, importdevice, getgrpdevices, getSettingsfilename 
 * @author : Asha Bheda
 *
 *********************************************************************************************/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class DmanGroupService {

  constructor(private http: HttpClient) { }

  AddGrp(data): Observable<any> {
    return this.http.post('/addgrp', data);
  }
  getgrps(): Observable<any> {
    return this.http.get('/getgrps');
  }

  deletegrp(id): Observable<any> {
    console.log("delete group service", id);
    
    return this.http.delete(`/deletegrp/${id}`);
  }
  EditGrp(data, id, update_mismatch_flag,oldsetting): Observable<any> {
    return this.http.post(`/editgrp`,{id:id, data:data, update_mismatch_flag:update_mismatch_flag ,oldsetting:oldsetting});
  }
  importdevice(data, grp_id): Observable<any> {
    return this.http.post('/importdevice', { data: data, grp_id: grp_id });
  }
  getgrpdevices(grpid): Observable<any>{
    return this.http.put('/getgrpdevices',{grpid:grpid});
  }
  getSettingsfilename(): Observable<any> {
    return this.http.get('/getSettingsfilename');
  }
}
