import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchdeviceService {

  constructor(private http: HttpClient) { }
  searchDevices(selectedOption,deviceNumber,inactiveDevices): Observable<any>{
    console.log("devises list from service file",selectedOption,deviceNumber,inactiveDevices);
    //return this.http.get(`/searchAccount/${searchString}`);
    return this.http.get(`/searchDevices/${selectedOption},${deviceNumber},${inactiveDevices}`);
 
   }
   reboot(esn): Observable<any> {
    return this.http.put(`/reboot/${esn}`, { responseType: 'text' });
  }
  // clear(esn): Observable<any> {
  //   return this.http.put(`/clear/${esn}`, { responseType: 'text' });
  // }
    
}
