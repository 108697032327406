/*!
 **********************************************************************************************
 * Company name: Jenex Technologies
 *
 * @file : transfer.service.ts
 * @file description: APIs - getFrom, sendAccId, deviceTransfer
 * @author : Asha Bheda
 *
 *********************************************************************************************/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TransferService {

  constructor(private http: HttpClient) { }

  // get all account data for "From and "To" filter
  getFrom(): Observable<any> {
    return this.http.get('/getFrom');
  }
  //get devices according to thier account id
  sendAccId(id): Observable<any> {
    return this.http.put(`/sendAccId/${id}`, { responseType: 'text' });
  }
  //send imei to transfer devices
  deviceTransfer(imei,to,check_history,comment,from):Observable<any>{
    // console.log("transfer log",imei,to,check_history,comment,from );
    

    return this.http.post('/deviceTransfer', {imei:imei,to:to,check_history:check_history,comment:comment,from:from});
  }
}
